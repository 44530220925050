import ActivityDrawer from '@/components/ActivityDrawer/ActivityDrawer';
import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import EditCoverLetterDialog from '@/components/Dialogs/EditCoverLetterDialog';
import type { SplitActionButton } from '@/components/MuiMenu';
import MuiMenu from '@/components/MuiMenu';
import MuiTabs from '@/components/MuiTabs';
import PDFViewer from '@/components/PDFViewer';
import { H1 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  GetReportAvailabilitySenderMethodAvailability,
  GetRpmReportsMonthlyResponse,
  RegenerateRpmReportResponse,
  reviewRpmReportResponse
} from '@/graphql/remotePatientMonitoring';
import {
  GET_RPM_REPORTS_MONTHLY,
  GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY,
  REGENERATE_RPM_REPORT,
  REVIEW_RPM_REPORT
} from '@/graphql/remotePatientMonitoring';
import type { CreatePdfRpmReportResponse, CreateRpmCoverLetterResponse } from '@/graphql/rpmWorkflow';
import { CREATE_PDF_RPM_COVER_LETTER, CREATE_PDF_RPM_REPORT } from '@/graphql/rpmWorkflow';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import { color } from '@/styles/assets/colors';
import { theme } from '@/styles/mui-theme';
import type { ReportMonth } from '@/types/remotePatientMonitoring';
import {
  ActivityLogType,
  ReportActivityLogTypes,
  ReportCommunicationActivityLogTypes,
  ReviewSubmitTypeEnum
} from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { fetchDocumentUrl, generateDynamicContent } from '@/util/rpm';
import { useAccountStore } from '@/zustand/AccountStore';
import { useRpmWorkflowStore } from '@/zustand/SessionTimers';
import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import { Box, LinearProgress, Paper, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

export enum RpmWorkflowReportType {
  RPM_REPORT,
  COVER_LETTER
}

export type ProviderContactType = {
  email: string | null;
  phone: string | null;
  fax: string | null;
};

export type MessageAvailabilityType = {
  isConfigAvailability: boolean;
  message: {
    providers: {
      providerName: string;
      providerMethodAvailability: {
        isRpmReportProviderByEmail: boolean;
        isRpmReportProviderByText: boolean;
        isRpmReportProviderByFax: boolean;
      };
      providerContacts: ProviderContactType;
    }[];
    patients: {
      patientName: string;
      patientMethodAvailability: {
        isRpmReportPatientByEmail: boolean;
        isRpmReportPatientByText: boolean;
      };
      patientContacts: Partial<ProviderContactType>;
    };
    orgProviders: {
      providerName: string;
      providerMethodAvailability: {
        isRpmReportProviderByEmail: boolean;
        isRpmReportProviderByText: boolean;
        isRpmReportProviderByFax: boolean;
      };
      providerContacts: ProviderContactType;
    }[];
  };
};

const Reports = () => {
  const { isReadOnly } = useRpmWorkflowStore();
  const { id: truentityId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();
  const { patientInfo } = useContext(PatientDetailContext);
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { patientReviewWorkflowMonthYear, setPatientReviewWorkflowMonthYear, setShowPatientReviewWindow } = useAccountStore();

  const [selectedReportType, setSelectedReportType] = useState<RpmWorkflowReportType>(RpmWorkflowReportType.RPM_REPORT);
  const [reportMonth, setReportMonth] = useState<ReportMonth>({} as ReportMonth);
  const [rpmReportUrl, setRpmReportUrl] = useState<string>('');
  const [rpmCoverLetterUrl, setRpmCoverLetterUrl] = useState<string>('');
  const [isActivityDrawerOpen, setIsActivityDrawerOpen] = useState(false);
  const [reportMenuOptions, setReportMenuOptions] = useState<SplitActionButton[]>([]);
  const [dynamicConfirmationMessage, setDynamicConfirmationMessage] = useState<string>('');
  const [isPatientProvidersAvailable, setIsPatientProvidersAvailable] = useState<boolean>(false);
  const [isCompanyProvidersAvailable, setIsCompanyProvidersAvailable] = useState<boolean>(false);

  const isCurrentMonthReport = reportMonth.label === 'Current';

  const [fetchMonthlyRpmReports, { data: rpmMonthlyReportData, refetch: refetchRpmMonthlyReports }] =
    useLazyQuery<GetRpmReportsMonthlyResponse>(GET_RPM_REPORTS_MONTHLY, {
      variables: {
        truentityId
      },
      fetchPolicy: 'cache-and-network'
    });

  const { data: reportSenderMethodAvailability } = useQuery<GetReportAvailabilitySenderMethodAvailability>(
    GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY,
    { variables: { truentityId }, fetchPolicy: 'cache-and-network' }
  );

  const [createRpmReport, { error: createRpmReportError, loading: rpmReportLoading }] = useMutation<CreatePdfRpmReportResponse>(
    CREATE_PDF_RPM_REPORT,
    {
      onCompleted: async rpmReportData => {
        if (
          rpmReportData?.createPdfRpmReport?.rpmReport &&
          selectedReportType === RpmWorkflowReportType.RPM_REPORT &&
          isCurrentMonthReport
        ) {
          const createdRpmReport = rpmReportData?.createPdfRpmReport?.rpmReport;
          const previewUrl = await fetchDocumentUrl(createdRpmReport?.reportedDocS3Key);
          setRpmReportUrl(previewUrl);
        } else if (rpmReportData?.createPdfRpmReport?.errors?.length > 0) {
          setRpmReportUrl('');
          enqueueSnackbar(rpmReportData.createPdfRpmReport.errors[0], { variant: 'error' });
        }
      }
    }
  );
  const [createRpmCoverLetter, { error: createRpmCoverLetterError, loading: rpmCoverLetterLoading }] =
    useMutation<CreateRpmCoverLetterResponse>(CREATE_PDF_RPM_COVER_LETTER, {
      onCompleted: async rpmCoverLetterData => {
        if (rpmCoverLetterData?.createPdfRpmCoverLetter?.pdfDownloadUrl) {
          const previewUrl = await fetchDocumentUrl(rpmCoverLetterData.createPdfRpmCoverLetter.pdfDownloadUrl);
          setRpmCoverLetterUrl(previewUrl);
        } else if (rpmCoverLetterData?.createPdfRpmCoverLetter?.errors?.length > 0) {
          setRpmCoverLetterUrl('');
          enqueueSnackbar(rpmCoverLetterData.createPdfRpmCoverLetter.errors[0], { variant: 'error' });
        }
      }
    });
  const [reviewRpmReport, { loading: reviewRpmReportLoading }] = useMutation<reviewRpmReportResponse>(REVIEW_RPM_REPORT);
  const [regenerateRpmReport, { loading: regenerateRpmReportLoading }] = useMutation<RegenerateRpmReportResponse>(REGENERATE_RPM_REPORT);

  const handleEditCoverLetter = () => {
    const modal = showModal(EditCoverLetterDialog, {
      title: 'Edit Cover Letter Content',
      onCoverLetterUpdated: async () => {
        await createRpmCoverLetter({
          variables: {
            truentityId: truentityId
          }
        });
      },
      hideDialog: () => {
        modal.hide();
      }
    });
  };

  const handelReportLogs = () => setIsActivityDrawerOpen(true);

  const closeActivityDrawer = () => setIsActivityDrawerOpen(false);

  const clearPatientReviewWindowState = useCallback(() => {
    if (patientReviewWorkflowMonthYear !== null) setPatientReviewWorkflowMonthYear(null);
  }, [patientReviewWorkflowMonthYear, setPatientReviewWorkflowMonthYear]);

  const changePatientReviewWindowMonthYearState = useCallback(
    (monthYear: string) => {
      if (patientReviewWorkflowMonthYear !== monthYear) setPatientReviewWorkflowMonthYear(monthYear);
    },
    [patientReviewWorkflowMonthYear, setPatientReviewWorkflowMonthYear]
  );

  const handleRpmReportReview = useCallback(
    async (reviewType: ReviewSubmitTypeEnum) => {
      try {
        if (!reportMonth?.id && reportMonth?.label !== 'Current') {
          enqueueSnackbar('Could not load RPM report for review', {
            variant: 'error'
          });
          return;
        }
        const { data, errors } = await reviewRpmReport({
          variables: {
            rpmReportId: reportMonth.id,
            truentityId: truentityId,
            reviewSubmitType: reviewType
          }
        });

        if (data?.reviewRpmReport) {
          const { status, message } = data.reviewRpmReport;
          const variant = status === 'Success' ? 'success' : 'error';

          enqueueSnackbar(message, {
            variant
          });
        }
        if (errors) {
          console.error('Error in ReviewRpmReport:', errors);
          enqueueSnackbar('Unable to send RPM report for review', {
            variant: 'error'
          });
        }
      } catch (error) {
        console.error('Error in ReviewRpmReport:', error);
        enqueueSnackbar('Unable to send RPM report for review', {
          variant: 'error'
        });
      }
    },
    [enqueueSnackbar, reportMonth.id, reviewRpmReport]
  );

  const regenerateMonthlyRpmReport = async () => {
    try {
      const { data, errors } = await regenerateRpmReport({
        variables: {
          reportIds: [reportMonth.id]
        }
      });

      if (data?.regenerateRpmReports) {
        const { status, message } = data.regenerateRpmReports;
        const variant = status === 'Success' ? 'success' : 'error';

        if (status === 'Success') {
          refetchRpmMonthlyReports();
        }
        enqueueSnackbar(message, {
          variant
        });
      }
      if (errors) {
        console.error('Error in regenerateRpmReport:', errors);
        enqueueSnackbar('Unable to regenerate RPM report', {
          variant: 'error'
        });
      }
    } catch (error) {
      console.error('Error in regenerateRpmReport:', error);
      enqueueSnackbar('Unable to regenerate RPM report', {
        variant: 'error'
      });
    }
  };

  const handleConfirmationModal = useCallback(
    (reviewType: ReviewSubmitTypeEnum) => {
      const modal = showModal(ConfirmDialog, {
        title:
          reviewType === 'SEND_NOW'
            ? 'Are you sure you want to send this report for review?'
            : 'Are you sure you want to submit this report as ready for review?',
        message: dynamicConfirmationMessage,
        onAgree: () => {
          if (!isPatientProvidersAvailable || !isCompanyProvidersAvailable) {
            if (!isCompanyProvidersAvailable) {
              enqueueSnackbar(
                <Box>
                  Please select and{' '}
                  <Link
                    style={{
                      color: color.white
                    }}
                    to={`/administration/system/companies/${currentUser?.relyingParty.id}`}
                  >
                    configure
                  </Link>{' '}
                  company providers to send the report to.
                </Box>,
                { variant: 'error' }
              );
              return;
            } else {
              enqueueSnackbar(
                <Box>
                  Please select and{' '}
                  <Link
                    style={{
                      color: color.white
                    }}
                    to={`/patients/${truentityId}/details/rpm/patient-setup/care-activity/configuration`}
                  >
                    configure
                  </Link>{' '}
                  patient providers to send the report to.
                </Box>,
                { variant: 'error' }
              );
              return;
            }
          } else {
            handleRpmReportReview(reviewType);
            modal.hide();
          }
        },
        onDisagree: () => {
          modal.hide();
        },
        fullWidth: true,
        maxWidth: 'md'
      });
    },
    [dynamicConfirmationMessage, handleRpmReportReview, isCompanyProvidersAvailable, isPatientProvidersAvailable]
  );

  const regenerateRpmMonthReport = useCallback(
    (selectedMonth: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Are you sure?',
        message: `Are you sure you want to regenerate the report for <b>${selectedMonth}</b>? Regenerating the report will create a new version for the selected month, and access to the <b>previous report</b> for <b>${selectedMonth}</b> will no longer be available.`,
        onAgree: () => {
          regenerateMonthlyRpmReport();
          modal.hide();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [dynamicConfirmationMessage, regenerateMonthlyRpmReport]
  );

  const handleReviewReport = useCallback(() => {
    setShowPatientReviewWindow(true);
  }, [setShowPatientReviewWindow]);

  useEffect(() => {
    const fetchData = async () => {
      if (selectedReportType === RpmWorkflowReportType.RPM_REPORT) {
        if (rpmReportUrl === '') {
          if (isCurrentMonthReport) {
            createRpmReport({
              variables: {
                truentityId: truentityId
              }
            });
          } else if (reportMonth.docS3Key) {
            const pdfUrl = await fetchDocumentUrl(reportMonth.docS3Key);
            setRpmReportUrl(pdfUrl);
          }
        }
      }
    };
    fetchData();
  }, [reportMonth]);

  useEffect(() => {
    if (createRpmReportError) {
      enqueueSnackbar('Could not get RPM Summary Report.', { variant: 'error' });
    }
  }, [createRpmReportError]);

  useEffect(() => {
    if (createRpmCoverLetterError) {
      enqueueSnackbar('Could not get RPM Cover Letter.', { variant: 'error' });
    }
  }, [createRpmCoverLetterError]);

  useEffect(() => {
    if (rpmMonthlyReportData?.getRpmReportsMonthly) {
      const months = rpmMonthlyReportData.getRpmReportsMonthly;
      const refactoredMonths = months.map(item => {
        return {
          label: item.monthYearName,
          disable: false,
          onAction: () => {
            setReportMonth(prev => {
              if (prev.id !== item.report.id) {
                setRpmReportUrl('');
                return {
                  id: item.report.id,
                  label: item.monthYearName,
                  docS3Key: item.report.reportedDocS3Key
                };
              }
              return prev;
            });
            changePatientReviewWindowMonthYearState(item.monthYearName);
          }
        };
      });

      const currentElement = {
        label: 'Current',
        disable: false,
        onAction: () => {
          setReportMonth(prev => {
            if (prev.id !== '') {
              setRpmReportUrl('');
              return { id: '', label: 'Current' };
            }
            return prev;
          });
          clearPatientReviewWindowState();
        }
      };
      refactoredMonths.unshift(currentElement);
      setReportMenuOptions(refactoredMonths);
    }
  }, [changePatientReviewWindowMonthYearState, clearPatientReviewWindowState, rpmMonthlyReportData?.getRpmReportsMonthly]);

  useEffect(() => {
    if (selectedReportType === RpmWorkflowReportType.COVER_LETTER) {
      if (rpmCoverLetterUrl === '') {
        createRpmCoverLetter({
          variables: {
            truentityId: truentityId
          }
        });
      }
    } else {
      setRpmCoverLetterUrl('');
      fetchMonthlyRpmReports();
    }
  }, [selectedReportType]);

  useEffect(() => {
    if (reportSenderMethodAvailability?.getRpmReportMethodAvailability?.jsonMessage) {
      const jsonObj = JSON.parse(reportSenderMethodAvailability?.getRpmReportMethodAvailability.jsonMessage ?? '');
      const methodAvailability: MessageAvailabilityType = {
        isConfigAvailability: jsonObj?.isConfigAvailability,
        message: {
          providers: jsonObj?.message?.providers?.map(provider => ({
            providerName: provider?.providerName,
            providerMethodAvailability: {
              isRpmReportProviderByEmail: provider?.providerMethodAvailability?.isRpmReportProviderByEmail,
              isRpmReportProviderByText: provider?.providerMethodAvailability?.isRpmReportProviderByText,
              isRpmReportProviderByFax: provider?.providerMethodAvailability?.isRpmReportProviderByFax
            },
            providerContacts: provider?.providerContacts
          })),
          patients: {
            patientName: jsonObj?.message?.patients?.patientName,
            patientMethodAvailability: {
              isRpmReportPatientByEmail: jsonObj?.message?.patients?.patientMethodAvailability?.isRpmReportPatientByEmail,
              isRpmReportPatientByText: jsonObj?.message?.patients?.patientMethodAvailability?.isRpmReportPatientByText
            },
            patientContacts: jsonObj?.message?.patients?.patientContacts
          },
          orgProviders: jsonObj?.message?.orgProviders?.map(provider => ({
            providerName: provider?.providerName,
            providerMethodAvailability: {
              isRpmReportProviderByEmail: provider?.providerMethodAvailability?.isRpmReportProviderByEmail,
              isRpmReportProviderByText: provider?.providerMethodAvailability?.isRpmReportProviderByText,
              isRpmReportProviderByFax: provider?.providerMethodAvailability?.isRpmReportProviderByFax
            },
            providerContacts: provider?.providerContacts
          }))
        }
      };
      const content = generateDynamicContent(methodAvailability);
      setDynamicConfirmationMessage(content);
      if (jsonObj?.message?.providers?.length > 0) {
        setIsPatientProvidersAvailable(true);
      }
      if (jsonObj?.message?.orgProviders?.length > 0) {
        setIsCompanyProvidersAvailable(true);
      }
    } else {
      setIsPatientProvidersAvailable(false);
      setIsCompanyProvidersAvailable(false);
    }
  }, [reportSenderMethodAvailability]);

  useEffect(() => {
    if (patientReviewWorkflowMonthYear && patientReviewWorkflowMonthYear.length > 0 && reportMenuOptions.length > 0) {
      let actionTriggered = false;

      for (const option of reportMenuOptions) {
        if (option.label?.toString().toLowerCase() === patientReviewWorkflowMonthYear.toLowerCase() && !actionTriggered) {
          if (option.onAction) {
            option.onAction();
            actionTriggered = true;
            break;
          }
        }
      }

      if (!actionTriggered && reportMenuOptions[0].onAction) {
        reportMenuOptions[0].onAction();
      }
    } else if (reportMenuOptions.length > 0 && reportMenuOptions[0].onAction) {
      reportMenuOptions[0].onAction();
    }
  }, [patientReviewWorkflowMonthYear, reportMenuOptions]);

  return (
    <BaseRpmWorkflowTabContent
      sx={{
        background: theme.palette.background.paper,
        boxShadow: 'none'
      }}
      isReadOnly={isReadOnly}
    >
      <Stack display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'stretch'} width={'100%'}>
        <Stack width="100%" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
          <Stack flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
            <H1 textAlign="left" sx={{ fontSize: '38px', color: color.black50, lineHeight: '50px' }}>
              Reports
            </H1>
            <Stack width="100%" flex={1} flexDirection="row" justifyContent="flex-end" alignItems="center">
              <Box
                sx={{
                  margin: 1
                }}
              >
                <MuiMenu
                  variant="contained"
                  ButtonProps={{
                    isLoading: reviewRpmReportLoading
                  }}
                  disabled={reviewRpmReportLoading || !reportMonth}
                  label="Send for Review"
                  options={[
                    {
                      label: 'Schedule for Later',
                      disabled: isCurrentMonthReport,
                      onAction: () => handleConfirmationModal(ReviewSubmitTypeEnum.READY_TO_SEND)
                    },
                    {
                      label: 'Send Now',
                      onAction: () => handleConfirmationModal(ReviewSubmitTypeEnum.SEND_NOW)
                    }
                  ]}
                />
              </Box>
              <Box sx={{ margin: 1 }}>
                <Button
                  disabled={selectedReportType !== RpmWorkflowReportType.RPM_REPORT || isCurrentMonthReport}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleReviewReport}
                >
                  Review
                </Button>
              </Box>
              <Box sx={{ margin: 1 }}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handelReportLogs();
                  }}
                >
                  Audit Summary
                </Button>
              </Box>
            </Stack>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-end" alignItems="center">
            <Button
              disabled={selectedReportType === RpmWorkflowReportType.RPM_REPORT}
              type="button"
              variant="contained"
              color="primary"
              onClick={handleEditCoverLetter}
              sx={{
                margin: 1
              }}
            >
              Edit Cover Letter
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              isLoading={regenerateRpmReportLoading}
              onClick={() => regenerateRpmMonthReport(reportMonth.label)}
              disabled={!reportMonth || isCurrentMonthReport}
              sx={{
                margin: 1
              }}
            >
              Regenerate the report
            </Button>
          </Stack>
        </Stack>
      </Stack>
      <Paper sx={{ background: theme.palette.background.default }}>
        <MuiTabs
          tabVariant="standard"
          sx={{}}
          onTabChange={selectedTabIndex => {
            if (selectedTabIndex === 0) {
              setSelectedReportType(RpmWorkflowReportType.RPM_REPORT);
            } else {
              setSelectedReportType(RpmWorkflowReportType.COVER_LETTER);
            }
          }}
          tabs={[
            {
              label: 'RPM Report',
              children: (
                <Stack>
                  <Stack
                    sx={{
                      m: 1
                    }}
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <MuiMenu disabled={isReadOnly} label={reportMonth.label} options={reportMenuOptions} />
                  </Stack>
                  {rpmReportLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Stack
                    width="100%"
                    height="100%"
                    sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                  >
                    <PDFViewer src={rpmReportUrl} />
                  </Stack>
                </Stack>
              )
            },
            {
              label: 'Cover Letter',
              children: (
                <Stack>
                  {rpmCoverLetterLoading ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <></>
                  )}
                  <Stack
                    width="100%"
                    height="100%"
                    sx={{ border: '1px solid #ccc', height: '750px', justifyContent: 'stretch', alignItems: 'stretch' }}
                  >
                    <PDFViewer src={rpmCoverLetterUrl} />
                  </Stack>
                </Stack>
              )
            }
          ]}
        />
      </Paper>
      <ActivityDrawer
        isOpen={isActivityDrawerOpen}
        onClose={closeActivityDrawer}
        type={ActivityLogType.REPORTS_SUMMARY}
        filterOptions={{
          typeFilters: [...new Set(Object.values(ReportActivityLogTypes).concat(Object.values(ReportCommunicationActivityLogTypes)))]
        }}
        accountName={getAccountUserFullName(patientInfo?.user)}
      />
    </BaseRpmWorkflowTabContent>
  );
};

export default Reports;
